export const sendMessageTitle = "Send Message";
export const groups = "Groups";
export const subGroupTitle = "Sub-group";
export const addGroupTitle = "Add-group";
export const editGroupTitle = "Edit-group";
export const apiKeyTitle = "API Key";
export const addressBookTitle = "Address book";
export const addAddressBookTitle = "Add Address book";
export const editAddressBookTitle = "Edit Address book";
export const crmTitle = "CRM Settings";
export const manageTemplateTitle = "Template";
export const settingTitle = "Settings";
export const addTemplateTitle = "Add Template";
export const editTemplateTitle = "Edit Template";
export const senderId = "Manage Sender ID";
export const dltEntityId = "Manage Dlt Entity Id";
export const dashboard = "Dashboard";
export const reportTitle = "Reports";
export const summaryReportTitle = "Summary Reports";
export const PauseResumeTitle = "Pause/Resume User SMS";
export const PurgeSMSTitle = "Purge SMS";
export const keywordDetailReport = "Keyword Detail Report";
export const WHITEBLACKLIST = "Black-White List";
export const apiKeyAddTitle = "Add API Key";
export const messageLogReportTitle = "Message Log Reports";
export const IMPORT_TITLE = "Import";
export const MANAGE_KEYWORDS = "Manage Keywords";
export const MANAGE_KEYWORD_SEPARATORS = "Manage Keyword Separators";
export const MANAGE_KEYWORD_INTEGRATION = "Manage Keyword Integration";
export const KEYWORD_INTEGRATION = "Keyword Integration";
export const SEND_EMAIL_REPORT_TITLE = "Send As Email";
export const SEND_EMAIL_REPORT_LIST_TITLE = "Report As Email List";
export const CRM_STATUS_TITLE = "CRM Status";
export const MANAGE_SMS_USER = "Sub User";
export const MANAGE_SMS_USER_VIEW = "View Sub Users";
export const ADD = "Add";
export const SCHEDULE_SMS_REPORT_TITLE = "Schedule Sms Summary";
export const messageOverrideSettingsTitle = "Override Message Settings";
export const DUPLICATE_SMS_CONFIGURATION_TITLE = "Duplicate SMS Configuration";
export const dltTemplate = "Manage DLT Template";