import { Injectable } from '@angular/core';

import { ApiService } from '../shared/service';

@Injectable()
export class MessageService {

  constructor(private apiService: ApiService) { }

  public makeFileRequest(files: File[], source: any, url: any, getResponse: any) {
    try {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i], files[i].name);
        formData.append("source", source);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            getResponse(xhr.response);
          } else {
            this.handleError(xhr.response);
            getResponse(xhr.response);
          }
        }
      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader('accept', 'application/json');
      xhr.setRequestHeader('token', this.apiService.getToken);
      xhr.setRequestHeader('session-id', this.apiService.getSessionId);
      xhr.send(formData);

    } catch (error) {
      console.log(error);
    }
  }

  private handleError(error: any) {
    try {
      let description = 'There was an error: ' + error.status;
      let errors = { 'error-code': error.status, 'error-status': error.statusText, 'error-description': description };
      return { status: errors };
    } catch (error) {
      console.log(error);
    }
  }



}
